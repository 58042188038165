import React, { useState } from "react"

import useGlobalStyles from "../global-styles"

const Home = ({
  title,
  renderDescription,
  noeStory,
  productsTitle,
  renderProducts,
}) => {
  const [selectedTab, setSelectedTab] = useState("own")
  const [showArchived, setShowArchived] = useState(false)
  const globalClasses = useGlobalStyles()

  return (
    <div className={globalClasses.home}>
      <center>
        <h1 style={{ fontSize: 50, marginTop: 8 }}>{title}</h1>
      </center>
      <div style={{ fontSize: 22, marginTop: 40 }}>{renderDescription()}</div>
      <div style={{ marginTop: 25 }}>{noeStory}</div>
      <div style={{ marginTop: 45 }}>
        <div style={{ marginBottom: 15 }}>
          <span
            onClick={() => setSelectedTab("own")}
            onKeyDown={() => setSelectedTab("own")}
            role="button"
            tabIndex={0}
            style={{
              fontSize: 22,
              fontWeight: "bold",
              borderBottom: selectedTab === "own" && "1px solid #f8f8f8",
              opacity: selectedTab !== "own" && 0.5,
              cursor: "pointer",
              outline: "none",
              MozUserSelect: "none",
              WebkitUserSelect: "none",
              msUserSelect: "none",
            }}
          >
            {productsTitle}
          </span>
        </div>
        {renderProducts()}
      </div>
    </div>
  )
}

export default Home
