import React from "react"
import { Grid, Box } from "@mui/material"
import LinkIcon from "@mui/icons-material/Link"
import { orderBy } from "lodash"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "../components/home"
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun"
import ArchitectureIcon from "@mui/icons-material/Architecture"
// import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined"
// import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined"
import CurrencyLiraOutlinedIcon from "@mui/icons-material/CurrencyLiraOutlined"
// import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined"
import CropOriginalIcon from "@mui/icons-material/CropOriginal"

const products = [
  //   {
  //   id: `runwildpeanut`,
  //   name: `Run Wild Peanut`,
  //   url: `https://runwildpeanut.com`,
  //   image: PeanutImg,
  //   description: 'a community and apparel store for outdoor and nature enthusiasts. #stay cozy and adventure on.',
  //   year: 2023,
  //   status: 'soon',
  // },
  // {
  //   id: `dogfriendlyplaces`,
  //   name: `Dog-Friendly Places`,
  //   url: `https://dogfriendlyplaces.co`,
  //   image: `https://dogfriendlyplaces.co/assets/logo.png`,
  //   description:
  //     "a dedicated app to find dog-friendly locations around the world. discover the best dog-friendly cafes, parks, and shops.",
  //   year: 2024,
  //   icon: <PetsOutlinedIcon />,
  // },
  {
    id: `kvikapp`,
    name: `Kvik App`,
    url: `https://kvikapp.com`,
    image: `https://kvikapp.com/assets/logo.png`,
    description:
      "a dedicated running app to customize workouts, optimize training routines, and monitor trainee progression in real-time.",
    year: 2024,
    icon: <DirectionsRunIcon />,
  },
  // {
  //   id: `printabletools`,
  //   name: `Printable Tools`,
  //   url: `https://printable.tools`,
  //   image: `https://makeprintable.ai/logo-white.png`,
  //   description:
  //     "an online tool set that helps you to create free printables in seconds such as coloring books, flashcards and more.",
  //   year: 2024,
  //   icon: <HomeRepairServiceOutlinedIcon />,
  // },
  {
    id: `mockupgenerator`,
    name: `Mockup Generator`,
    url: `https://mockupgenerator.co`,
    image: `https://mockupgenerator.co/logo.png`,
    description:
      "an online tool that helps you to create product mockups in seconds and saves a lot of time with bulk mockup generations.",
    year: 2023,
    icon: <ArchitectureIcon />,
  },
  {
    id: `temettu`,
    name: "Temettü App",
    url: `https://temettu.app`,
    image: `https://pbs.twimg.com/profile_images/1257605894449307648/C3GmzkiK_400x400.jpg`,
    description: `a mobile app that helps you to follow the dividend calendar and track the dividend payments of your stock investments.`,
    year: 2020,
    icon: <CurrencyLiraOutlinedIcon />,
  },
]

// const archived = [
//   {
//     id: `makeprintable`,
//     name: `Make Printable AI`,
//     url: `https://makeprintable.ai`,
//     image: `https://gogiftem.com/static/logo-55e9dd7ecfb57ba7988672ec7cb9c203.png`,
//     description:
//       "a web app powered by AI for creating high-resolution designs in seconds for print materials.",
//     year: 2023,
//     icon: <CropOriginalIcon />,
//   },
//   {
//     id: `gogiftem`,
//     name: `Go Gift'em`,
//     url: `https://gogiftem.com`,
//     image: `https://gogiftem.com/static/logo-55e9dd7ecfb57ba7988672ec7cb9c203.png`,
//     description:
//       "a web app to explore curated gift ideas. discover unique and creative gift ideas with our handpicked collections.",
//     year: 2022,
//     icon: <CardGiftcardOutlinedIcon />,
//   },
// {
//   id: `langlearngames`,
//   name: `Lang Learn Games`,
//   url: `https://langlearngames.com`,
//   image: `https://langlearngames.com/static/logo-2c9cae6ae8a657a1e147ea8fa50dbad7.png`,
//   description:
//     "a dictionary for educational games for learning languages through gamified journeys.",
//   year: 2023,
//   icon: <LanguageOutlinedIcon />,
// },
// ]

const IndexPage = () => {
  const renderProducts = () => {
    const cardStyle = {
      position: "relative",
      borderRadius: "8px",
      padding: { xs: "15px", md: "5px 10px" },
      transition: "all .1s ease-in-out",
      display: "flex",
      minHeight: 140,
      alignItems: "center",
      "&:hover": {
        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.2)",
      },
      "&:hover .iconOpacity": {
        opacity: 0.9,
      },
    }

    const tagStyle = {
      fontSize: "12px",
      opacity: 0.6,
      padding: "0 4px",
      borderRadius: "5px",
      height: "20px",
      marginLeft: "7px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(255, 255, 255, 0.1)",
    }

    return (
      <Grid container spacing={{ xs: 2, md: 1 }} style={{ marginBottom: 20 }}>
        {orderBy(products, "year", "desc").map(product => (
          <Grid item xs={12} key={product.id}>
            <a
              href={product.url}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#FFFFFF", textDecoration: "none" }}
            >
              <Box
                sx={cardStyle}
                style={{ opacity: product.status === "soon" ? 0.7 : 1 }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    opacity: 0,
                    transition: "opacity .2s",
                  }}
                  className="iconOpacity"
                >
                  <LinkIcon />
                </Box>

                {product.image ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      width: 70,
                      height: 70,
                      minHeight: 70,
                      minWidth: 70,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "20px",
                      borderRadius: "100px",
                      color: "#000",
                    }}
                  >
                    {product?.icon}
                  </div>
                ) : (
                  <Box
                    style={{
                      width: "100px",
                      marginRight: "15px",
                      height: "100px",
                    }}
                  >
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      }}
                    />
                  </Box>
                )}
                <Box>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {product.name} <span style={tagStyle}>{product.year}</span>
                  </div>
                  <div style={{ fontSize: "18px", marginTop: "10px" }}>
                    — {product.description}
                  </div>
                </Box>
              </Box>
            </a>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <Layout>
      <Seo />
      <Home
        title={`An idea could be anything.`}
        renderDescription={() => (
          <span>
            At <strong>Noe Crafts</strong>, we're passionate indie makers, software developers and creatives. We build cool stuff together and share our learnings.
          </span>

        )}
        noeStory={`Noe means 'something, anything' in Norwegian, funny story.`}
        productsTitle={`What we built`}
        renderProducts={renderProducts}
      />
    </Layout>
  )
}

export default IndexPage
